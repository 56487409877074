<template>
  <admin-template
    :title="alterId ? 'alterar post' : 'novo post'"
    backlink="/admin/posts"
  >
    <form>
      <fieldform text="titulo">
        <el-input v-model="formData.title"></el-input>
      </fieldform>

      <fieldform text="categoria">
        <el-select
          v-model="formData.categoryPostId"
          placeholder="selecione"
        >
          <el-option
            v-for="item in categoryTypes"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </fieldform>

      <vue-editor v-model="htmlText"></vue-editor>

      <div class="submit">
        <el-button
          @click="submit"
          type="primary"
        >
          salvar
        </el-button>
      </div>
    </form>
  </admin-template>
</template>

<script>
import api from '@/services/api'
import adminTemplate from '@/components/admintemplate.vue'
import fieldform from '@/components/fieldform.vue'
import { VueEditor } from "vue2-editor";

export default {
  name: 'product-category-handle',
  components: {
    adminTemplate,
    fieldform,
    VueEditor,
  },
  data() {
    return {
      htmlText: ``,
      formData: {
        title: null,
        categoryPostId: null,
      },
      categoryTypes: [{
        id: 1,
        name: 'carregando opções'
      }],
      alterId: this.$route.params.id || null
    }
  },
  methods: {
    submit() {
      const { formData } = this

      // ALTER DATA
      if (this.alterId) {
        api.put(`/cms/post/${this.alterId}`, {
          ...formData,
          content: this.htmlText,
        },
          {
            headers: {
              'x-access-token': localStorage.token,
              'Content-Type': 'application/json',
            },
            mode: 'no-cors'
          })
          .then(res => {
            this.showMessage({
              message: 'post alterado com sucesso',
              type: 'success'
            });

           this.$router.push('/admin/posts')
            return res
          })
      }
      // NEW DATA
      else {
        api.post(`/cms/post`, {
          ...formData,
          content: this.htmlText,
        },
          {
            headers: {
              'x-access-token': localStorage.token,
              'Content-Type': 'application/json',
            },
            mode: 'no-cors'
          })
          .then(res => {
            this.showMessage({
              message: 'post cadastrado com sucesso',
              type: 'success'
            });

           this.$router.push('/admin/posts')
            return res
          })
      }
    },
    getPostCategories() {
      api.get(`/cms/categoryPost`, {
        headers: {
          'x-access-token': localStorage.token,
          'Content-Type': 'application/json',
        },
        mode: 'no-cors'
      })
        .then(({ data }) => {
          if (data.length === 0) {
            this.categoryTypes = [{
              id: 0,
              name: 'nenhuma opção disponivel'
            }]
          }
          else {
            this.categoryTypes = data
          }
        })
        .catch(err => console.log(err))
    },
    getPostById(id) {
      api.get(`/cms/post/${id}`, {
        headers: {
          'x-access-token': localStorage.token,
          'Content-Type': 'application/json',
        },
        mode: 'no-cors'
      })
        .then(({ data }) => {
          this.htmlText = data.content
          this.formData.title = data.title
          this.formData.categoryPostId = data.categoryPostId
        })
        .catch(err => console.log(err))
    },
    showMessage(payload) {
      this.$message(payload)
    }
  },
  mounted() {
    this.getPostCategories()
    if (this.alterId) {
      this.getPostById(this.alterId)
    }
  },
}
</script>

<style scoped>
form {
  border: 1px solid #dedede;
  padding: 20px;
  border-radius: 10px;
  margin-top: 23px;
}

.el-select {
  width: 100%;
  margin-bottom: 20px;
}

.submit {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}
</style>
